import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/seo'

const ServicesPage = () => (
  <StaticQuery query={servicesQuery} render={data => {
    const { title, subTitle, image } = data.markdownRemark.frontmatter
    return (
      <>
        <SEO title="Services | BluePes" keywords={[`bluepes`, `services`, `IT`]} />
        <BackgroundImage
          className="background-image-services general-page-background"
          fluid={image.childImageSharp.fluid}
        >
          <div className="general-page-gradient">
            <div className="general-page-header wrapper">
              <h1 className="general-page-title">
                {title}
              </h1>
              <p className="general-page-description">
                {subTitle}
              </p>
            </div>
          </div>
        </BackgroundImage>

        <section className="services-page">
          <div className="general-page-wrapper">
            <div className="services-wrapper wrapper">
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <article className="service-item" key={node.id}>
                  <a className="service-item-link">
                    <div className="service-item-number-mobile">
                      {`0${ node.frontmatter.order }`}
                    </div>
                    <div className="service-item-img">
                      <Img
                        fluid={node.frontmatter.image.childImageSharp.fluid}
                        alt={node.frontmatter.title} />
                    </div>
                    <div className="service-item-info">
                      <div className="service-item-number-desktop">
                        {`0${ node.frontmatter.order }`}
                      </div>
                      <h1 className="service-item-header">
                        {node.frontmatter.title}
                      </h1>
                      <div
                        className="service-item-description"
                        dangerouslySetInnerHTML={{ __html: node.html }} />
                    </div>
                  </a>
                </article>
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }}
  />
)

export const servicesQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/services/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            order
            image {
              childImageSharp {
                fluid(
                  maxWidth: 715, 
                  quality: 85, 
                  srcSetBreakpoints: [344, 448, 544, 608 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    
     markdownRemark (
      fileAbsolutePath: {regex: "/partial/general-pages-header/general-services-header.md/"}
    ) {
      frontmatter {
        title
        subTitle
        image {
          childImageSharp {
            fluid(
              maxWidth: 1920, 
              quality: 80,
              srcSetBreakpoints: [375, 480, 768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
